<template>
  <el-drawer
    :size="1200"
    :visible.sync="drawer"
    :show-close="false"
    :withHeader="false"
    :wrapperClosable="false"
  >
    <div class="enquiry_detail flex flex-col pt-32">
      <div class="border_bottom flex justify-between items-center py-16 pr-16">
        <div class="flex items-center">
          <div class="has_offer title_label" v-if="enquiryObj.status == 3">
            已报价({{ enquiryObj.offeredCount }})
          </div>

          <div
            class="equirying  ml-8"
            v-if="enquiryObj.subStatus == 'enquirying'"
          >
            询价中
          </div>
          <div
            class="equirying_stop ml-8"
            v-else-if="enquiryObj.subStatus == 'stoped'"
          >
            停止询价
          </div>
        </div>
        <div>
          <i class="iconfont icon-line_fork cursor-pointer" @click="close"></i>
        </div>
      </div>
      <div class="flex-1 px-16 py-16  flex flex-col overflow-y-auto">
        <div class="enquiry_info mb-16">
          <el-row>
            <el-col :span="12">
              <div class="mt-4">
                <span class="color_6">车型：</span>
                <span v-if="enquiryObj.enquiryType == 0">
                  {{ enquiryObj.vehBrand }} {{ enquiryObj.factoryBrand }}
                  {{ enquiryObj.series }} {{ enquiryObj.year }}
                  {{ enquiryObj.displacement }} {{ enquiryObj.gearbox }}
                </span>
                <span v-else>
                  {{
                    enquiryObj.brandsScope && enquiryObj.brandsScope.length
                      ? enquiryObj.brandsScope.join("、")
                      : ""
                  }}
                </span>
              </div>
              <div class="mt-4">
                <span class="color_6">VIN码：</span>
                <span>{{ enquiryObj.vin }}</span>
              </div>
              <div class="mt-4">
                <span class="color_6">发票类型：</span>
                <span>{{ enquiryObj.invoiceType || "无限制" }}</span>
              </div>
              <div class="mt-4">
                <span class="color_6">品质要求：</span>
                <span>{{
                  enquiryObj.quality && enquiryObj.quality.length
                    ? enquiryObj.quality.join("、")
                    : "无限制"
                }}</span>
              </div>
              <div class="mt-4">
                <span class="color_6">供货地区：</span>
                <span>{{ renderPushArea(enquiryObj.pushArea) }}</span>
              </div>
              <div class="mt-4">
                <span class="color_6">备注：</span>
                <span>{{ enquiryObj.remarks }}</span>
              </div>
            </el-col>
            <el-col :span="12">
              <div>
                <span class="color_6">询价单号：</span>
                <span>{{ enquiryObj.enquiryCode }}</span>
              </div>
              <div>
                <span class="color_6">发布时间：</span>
                <span>{{ renderTime(enquiryObj.publishTime) }}</span>
              </div>
              <div class="flex items-center mt-4">
                <span class="color_6">发布人：</span>
                <span
                  >{{ enquiryObj.userName }}（{{ enquiryObj.companyName }}）-{{
                    enquiryObj.userPhone
                  }}</span
                >
              </div>

              <div class="mt-4">
                <el-button
                  type="text"
                  size="mini"
                  v-if="enquiryObj.pictures && enquiryObj.pictures.length"
                  @click="lookPictures()"
                >
                  查看图片（{{ enquiryObj.pictures.length }}）
                </el-button>
                <span class="color_6" v-else>暂无图片</span>
              </div>
            </el-col>
          </el-row>
        </div>
        <!-- <div class="border_bottom mb-16"></div> -->
        <div class="flex items-center text-sm font-semibold space-x-4">
          <span>共{{ enquiryObj.offeredCount }}家报价</span>
          <span>|</span>
          <span>{{ enquiryObj.selectedOfferCount }}家中标</span>
        </div>

        <div class="flex-1 flex space-x-8  mt-8">
          <div class=" h-full w-2/5 flex flex-col">
            <div class="flex-1 space-y-8">
              <!-- 商家列表 -->
              <template v-for="(item, index) in offerStore">
                <div
                  class="border_line store_item space-y-4 relative"
                  :class="[selectIndex == index ? 'border_active' : '']"
                  :key="index"
                  @click="selClickFn(item, index)"
                >
                  <div class="flex justify-between items-center">
                    <span class="text-xs font-semibold">{{
                      item.companyName
                    }}</span>
                    <div>
                      <i
                        class="iconfont icon-line_message1 icon_btn m-r-12"
                        @click="sendMsg(item)"
                      ></i>
                      <i
                        class="iconfont icon-line_home icon_btn"
                        @click="toStore(item)"
                      ></i>
                    </div>
                  </div>
                  <div class="flex space-x-4 text-xs">
                    <span>
                      本单报价时长：{{
                        renderDurationTime(item.offeredDuration)
                      }}
                    </span>
                    <span
                      >历史中标率：{{ renderRate(item.selectedRate) }}%</span
                    >
                  </div>
                  <div class="text-xs ">
                    报价完整度：{{ item.offeredCount }}/{{ item.enquiryCount }}
                  </div>
                  <div class="text-xs ">发货地：{{ item.deliverAreaName }}</div>
                  <div class="text-xs ">
                    报价有效期：{{ renderOverdueType(item.overdueType) }}
                    <span class="text-warning" v-if="item.expired"
                      >（已失效）</span
                    >
                  </div>
                  <div class="text-xs">
                    报价时间:{{ renderTime(item.offerTime) }}
                  </div>
                  <div
                    class="active_box"
                    v-if="selectIndex == index"
                  ></div>
                  <div
                    class="winning_box"
                    v-if="item.selectedCount > 0"
                  >
                    <div class="winning">已中标</div>
                  </div>
                </div>
              </template>
            </div>
            <div class="flex justify-end mt-8">
              <el-button size="small" plain @click="backFn">
                返回列表
              </el-button>
              <el-button
                v-if="enquiryObj.subStatus == 'enquirying'"
                size="small"
                plain
                @click="stopFn"
              >
                停止询价
              </el-button>
              <el-button
                v-if="enquiryObj.subStatus == 'stoped'"
                size="small"
                plain
                @click="startFn"
              >
                开启询价
              </el-button>
              <el-button type="primary" size="small" @click="againFn">
                再次询价
              </el-button>
            </div>
          </div>

          <div
            class="border_active h-full w-3/5 "
            :class="[selectIndex == 0 ? 'border_radius_l_t' : '']"
          >
            <EnquiryStoreSelect :curOffer="curOffer" @refresh="refreshFn" />
          </div>
        </div>
      </div>
    </div>
    <div class="demo-image__preview">
      <el-image
        ref="imgpreview"
        style="width: 0px; height: 0px"
        :src="enquiryUrl"
        :preview-src-list="srcList"
      >
      </el-image>
    </div>
  </el-drawer>
</template>

<script>
import EnquiryStoreSelect from "./enquiryStoreSelect.vue";
import {
  enquiryOfferList,
  // enquiryOfferDetail,
  enquiryStop,
  enquiryRestart,
} from "@/api/enquiry";

export default {
  name: "enquiry_detail",
  components: {
    EnquiryStoreSelect,
  },
  props: {
    enquiryCode: {
      type: String,
      require: true,
      default: "",
    },
  },
  data() {
    return {
      drawer: false,
      enquiryObj: {
        // enquiryCode: "", // # 询价单号
        // enquiryType: 0, //# 询价类型，0:车型询价，1:范围询价，2:通信快速询价
        // userId: 0, // # 发布者用户ID（users.id）
        // companyId: 0, // # 发布者公司ID (companies.id）
        // status: 3, //# 状态,1:待发布;2:待报价;3:已报价;
        // subStatus: "", // # 子状态,enquirying:询价中;stoped:停止询价;
        // vehBrand: "", //# 汽车品牌
        // factoryBrand: "", // # 汽车厂牌
        // series: "", //# 车系
        // year: "", //# 年款
        // displacement: "", // # 排量
        // gearbox: "", //# 变速器
        // vin: "", //# vin码
        // brandsScope: [
        //   //# 品牌范围
        // ],
        // pictures: [
        //   //# 上传的图片
        //   ],
        // remarks: "", //# 说明
        // invoiceType: "收据", // # 发票类型,存中文
        // quality: [
        //   //# 品质说明,存中文
        // ],
        // pushArea: "", //# 推送地区，none:无限制；thisCity:本市；thisProvince:本省
        // receiveAreaId: 0, //# 收货地址所在区域县级ID
        // receiveName: "", //# 收货地址中联系人
        // receivePhone: "", //# 收货地址中联系电话
        // receiveAddress: "", //# 收货地址中详细地址
        // receiverCount: 0, //# 接收者数量
        // publishTime: "", //# 询价发布时间
        // offeredCount: 1, //# 已报价人数
        // unreadOfferCount: 0, // # 未读报价单数
        // selectedOfferCount: 1, // # 中标商家数
        // companyName: "", //# 询价公司名称
        // companyType: "", //# 询价公司类型
        // userName: "", //# 询价人名称
        // userPhone: "", //# 询价人电话
        // content: [
        //   //# 询价内容
        // ],
      },
      offerStore: [
        //报价商家
      ],
      selectIndex: 0, //默认第一条
      curOffer: {},
      loadShow: null,
    };
  },
  watch: {
    pictures: {
      handler: function(newVal) {
        this.enquiryObj.pictures =
          newVal && newVal.length > 0
            ? newVal.map((item) => item.origin || item)
            : [];
      },
      deep: true,
    },
    drawer(val) {
      if (val) {
        this.getInit();
      }
    },
  },
  computed: {
    // activeTop() {
    //   let str = "";
    //   str = this.selectIndex * (170 + 8) - 5 + "px";
    //   return str;
    // },

    srcList() {
      return this.enquiryObj.pictures || [];
    },
    enquiryUrl() {
      return this.srcList[0];
    },
  },
  mounted() {
    this.getInit();
  },
  methods: {
    renderRate(rate) {
      let n = 0;
      if (rate == "1") {
        n = 100;
      } else if (rate == "0") {
        n = 0;
      } else {
        let r = rate.split(".")[1];
        if (r.length == 2) {
          n = Number(r);
        } else if (r.length == 1) {
          n = Number(r + "0");
        }
      }

      return n;
    },
    sendMsg(e) {
      this.$store.dispatch("setChatId", e.ownerUserId);
      this.$store.dispatch("changeIMVisible", true);
    },
    toStore(e) {
      this.drawer = false;
      let obj = {
        path: "/home/store",
        query: {
          storeId: e.companyId,
        },
      };
      this.$router.push(obj);
    },
    // winningTop(index) {
    //   let str = "";
    //   str = index * (170 + 8) + 115 + "px";
    //   return str;
    // },
    refreshFn() {
      this.getInit();
    },
    renderPushArea(pushArea) {
      let str = "";
      if (pushArea == "none") {
        str = "无限制";
      } else if (pushArea == "thisCity") {
        str = "本市";
      } else if (pushArea == "thisProvince") {
        str = "本省";
      }
      return str;
    },
    renderDurationTime(t) {
      //时长
      let day = Math.floor(t / 86400);
      let hour = Math.floor((t / 3600) % 24);
      let min = Math.floor((t / 60) % 60);
      let sec = Math.floor(t % 60);
      let str = "";
      str = `${day}天${hour}时${min}分${sec}秒`;
      return str;
    },
    renderTime(time) {
      return this.$dayjs(time).format("YYYY-MM-DD HH:mm:ss");
    },
    renderOverdueType(type) {
      //报价有效期
      let str = "";
      if (type == "none") {
        str = "始终有效";
      } else if (type == "oneHour") {
        str = "1小时";
      } else if (type == "oneDay") {
        str = "24小时";
      } else if (type == "threeDay") {
        str = "3天";
      } else if (type == "thirtyDay") {
        str = "30天";
      }
      return str;
    },
    getInit() {
      if (!this.enquiryCode) return;
      this.loadShow = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      enquiryOfferList({
        enquiryCode: this.enquiryCode,
        page: 1,
        pageSize: 20,
      }).then((res) => {
        this.enquiryObj = res?.enquiry || {};
        this.offerStore = res?.rows || [];
        this.selectIndex = 0;
        this.curOffer = this.offerStore?.[0] || {};
        if (res.pictures) {
          this.pictures = res.pictures.map((item) => {
            return {
              uid: item,
              url: item,
              origin: item,
            };
          });
        }
        this.loadShow.close();
      });
    },
    lookPictures() {
      document.querySelector(".demo-image__preview .el-image__preview").click();
    },
    show() {
      this.drawer = true;
    },
    close() {
      this.drawer = false;
      this.$emit("refresh");
    },
    selClickFn(item, index) {
      this.selectIndex = index;
      this.curOffer = { ...item };
    },
    backFn() {
      this.close();
    },
    stopFn() {
      this.$confirm(
        `询价单[${this.enquiryObj.enquiryCode}]确定要停止询价吗?`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        enquiryStop({
          enquiryId: this.enquiryObj.id,
        }).then(() => {
          this.$message.success("已停止询价！");
          this.getInit();
        });
      });
    },
    startFn() {
      enquiryRestart({
        enquiryId: this.enquiryObj.id,
      }).then(() => {
        this.$message.success("询价已开启！");
        this.getInit();
      });
    },
    againFn() {
      this.drawer = false;
      let obj = {
        path: "/home/postEnquiry",
        query: {
          enquiryCode: this.enquiryObj.enquiryCode,
        },
      };
      this.$router.push(obj);
    },
  },
};
</script>

<style lang="less" scoped>
.enquiry_detail {
  height: 100vh;
  .border_bottom {
    border-bottom: 1px solid #d9d9d9;
  }
  .border_top {
    border-top: 1px solid #d9d9d9;
  }
  .border_line {
    border: 1px solid #ededed;
    border-radius: 4px;
  }
  .border_active {
    border: 1px solid #ff6e4c;
    border-radius: 4px;
    // border-right: 1px solid #ffffff;
  }
  .border_radius_l_t {
    border-top-left-radius: 0;
  }
  .icon_btn {
    padding: 4px;
    background: #ffeeeb;
    border-radius: 50%;
    font-size: 16px;
    color: #ff6e4c;
    cursor: pointer;
  }
  .store_item {
    padding: 12px;
    // position: relative;

    // .active_box {
    //   width: 20px;
    //   height: 101%;
    //   background: #ffffff;
    //   position: absolute;
    //   top: -5px;
    //   right: -5px;
    //   z-index: 999;
    //   border-top: 1px solid #ff6e4c;
    //   border-bottom: 1px solid #ff6e4c;
    // }
    .active_box {
      width: 11px;
      // height: 170px;
      height: calc(100% + 2px);
      background: #ffffff;
      position: absolute;
      top: -5px;
      right: -10px;
      z-index: 999;
      border-top: 1px solid #ff6e4c;
      border-bottom: 1px solid #ff6e4c;
    }
    .winning_box {
      position: absolute;
      top: 115px;
      right: 1px;
      // left: 411px;
      overflow: hidden;
      width: 50px;
      height: 50px;
    }
    .winning {
      position: absolute;
      right: -18px;
      bottom: 8px;
      width: 70px;
      padding: 2px;
      font-size: 11px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: center;
      color: #ffffff;
      line-height: 16px;
      background: #3bba98;
      transform: rotate(315deg);
    }
  }
  .title_label {
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: left;
    color: #ffffff;
    line-height: 20px;
    padding: 2px 8px;
  }
  .wait_publish {
    width: fit-content;
    background: #bfbfbf;
    border-radius: 0px 29px 29px 0px;
  }
  .wait_offer {
    width: fit-content;
    background: #ff6e4c;
    border-radius: 0px 29px 29px 0px;
  }
  .has_offer {
    width: fit-content;
    background: #3bba98;
    border-radius: 0px 29px 29px 0px;
  }
  .equirying::before {
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    line-height: 8px;
    background: #3bba98;
    border-radius: 50%;
    margin-right: 8px;
  }
  .equirying_stop::before {
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    line-height: 8px;
    background: #ff4d4f;
    border-radius: 50%;
    margin-right: 8px;
  }
  .color_6 {
    color: #666666;
  }
  .color_3 {
    color: #333333;
  }
  .enquiry_info {
    background: #f4f4f4;
    border-radius: 2px;
    padding: 16px;
    font-size: 12px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: left;
    color: #333333;
    line-height: 20px;
    .qixiu {
      font-size: 12px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      color: #ff6e4c;
      line-height: 20px;
      background: #faeee1;
      border: 1px solid #ff6e4c;
      border-radius: 2px;
      padding: 2px 8px;
    }
    .el-button--text {
      font-size: 14px;
      padding: 0;
    }
    .info_flag {
      padding: 1px 8px;
      background: #f4f4f4;
      border: 1px solid #d9d9d9;
      border-radius: 2px;
    }
  }
  .enq_h1 {
    font-size: 16px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    text-align: left;
    color: #333333;
    line-height: 24px;
  }
  .enq_h2 {
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: left;
    color: #333333;
    line-height: 22px;
  }
}
</style>
