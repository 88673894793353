import request, { SERVICE_MODULE_MAP } from "@/utils/request";

//保存询价单
export function enquirySave(data){
  return request({
    baseURL: SERVICE_MODULE_MAP.coreModule,
    url: "/bill/enquiry/save",
    method: "POST",
    data
  })
}
//快速发布询价
export function enquiryPublishQuickly(data){
  return request({
    baseURL: SERVICE_MODULE_MAP.coreModule,
    url: "/bill/enquiry/publish-quickly",
    method: "POST",
    data
  })
}
//发布询价单
export function enquiryPublish(data){
  return request({
    baseURL: SERVICE_MODULE_MAP.coreModule,
    url: "/bill/enquiry/publish",
    method: "POST",
    data
  })
}
//通过询价单ID发布询价单
export function enquiryPublishById(data){
  return request({
    baseURL: SERVICE_MODULE_MAP.coreModule,
    url: "/bill/enquiry/publish-by-id",
    method: "POST",
    data
  })
}
//我的询价列表
export function enquiryList(data){
  return request({
    baseURL: SERVICE_MODULE_MAP.coreModule,
    url: "/bill/enquiry/list",
    method: "POST",
    data
  })
}
//停止询价
export function enquiryStop(data){
  return request({
    baseURL: SERVICE_MODULE_MAP.coreModule,
    url: "/bill/enquiry/stop",
    method: "POST",
    data
  })
}
//开启询价
export function enquiryRestart(data){
  return request({
    baseURL: SERVICE_MODULE_MAP.coreModule,
    url: "/bill/enquiry/restart",
    method: "POST",
    data
  })
}
//选中报价
export function enquirySelected(data){
  return request({
    baseURL: SERVICE_MODULE_MAP.coreModule,
    url: "/bill/enquiry/selected",
    method: "POST",
    data
  })
}
//报价列表
export function enquiryOfferList(data){
  return request({
    baseURL: SERVICE_MODULE_MAP.coreModule,
    url: "/bill/enquiry/offer_list",
    method: "POST",
    data
  })
}
//报价明细
export function enquiryOfferDetail(data){
  return request({
    baseURL: SERVICE_MODULE_MAP.coreModule,
    url: "/bill/enquiry/offer_detail",
    method: "POST",
    data
  })
}
//询价单详情
export function enquiryDetail(params) {
  return request({
    baseURL: SERVICE_MODULE_MAP.coreModule,
    url: "/bill/enquiry/detail",
    method: "GET",
    params
  })
}
//删除询价单
export function enquiryDelete(data){
  return request({
    baseURL: SERVICE_MODULE_MAP.coreModule,
    url: "/bill/enquiry/delete",
    method: "POST",
    data
  })
}