<template>
  <!-- 我的询价单 -->
  <div
    class="flex flex-col"
    :style="this.isSourceOfferManage() ? 'height: calc(100vh - 165px);' : 'height: calc(100vh - 48px);'"
  >
    <div v-if="!this.isSourceOfferManage()" class="bg-gray-200">
      <div class="content-box m-auto flex items-center py-16">
        <span class="mx-8 text-black-100">发布询价</span>
        <span class="mr-8">></span>
        <span class="mr-8 text-black-200">我的询价单</span>
      </div>
    </div>
    <div
      class="m-auto overflow-y-auto bg-white flex-1 w-full"
      :class="this.isSourceOfferManage() ? '' : 'content-box'"
    >
      <div
        class="enquiry h-full w-full flex flex-col"
        :class="this.isSourceOfferManage() ? '' : 'p-8'"
      >
        <SearchTemplate
          :formData="formData"
          labelWidth="80px"
          :placeholderText="placeholder"
          @handleQuery="handleQuery"
          @handleReset="handleReset"
        >
          <template slot="more">
            <el-form-item label="询价时间">
              <el-date-picker
                v-model="joinTime"
                type="daterange"
                align="right"
                unlink-panels
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
                @change="dateChangeFn"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="状态">
              <el-select
                v-model="formData.status"
                multiple
                placeholder="请选择状态"
              >
                <el-option
                  v-for="item in statusOptions"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="品质要求">
              <el-select
                v-model="formData.quality"
                multiple
                placeholder="品质要求"
              >
                <el-option
                  v-for="item in qualityOptions"
                  :key="item.code"
                  :label="item.name"
                  :value="item.name"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="发票类型">
              <el-select
                v-model="formData.invoiceType"
                multiple
                placeholder="发票类型"
              >
                <el-option
                  v-for="item in invoiceTypeOptions"
                  :key="item.code"
                  :label="item.name"
                  :value="item.name"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <!-- <el-form-item label="收货地区">
              <el-select
                v-model="formData.pushArea"
                clearable
                placeholder="收货地区"
              >
                <el-option
                  v-for="item in areaOptions"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </el-form-item> -->
            <el-form-item label="" label-width="20px">
              <el-checkbox v-model="formData.onlyEnquiry"
                >仅看询价中的</el-checkbox
              >
            </el-form-item>
            <el-form-item label="" label-width="20px">
              <el-checkbox v-model="formData.onlySelected"
                >仅看已中标的</el-checkbox
              >
            </el-form-item>
            <el-form-item label="" label-width="20px">
              <el-checkbox v-model="formData.onlyOfferUnRead"
                >仅看有未读报价的</el-checkbox
              >
            </el-form-item>
          </template>
          <template #right>
            <el-button type="primary" size="small" @click="toPostEnquiryFn"
              >发布询价</el-button
            >
          </template>
        </SearchTemplate>
        <div class="flex items-center mt-8">
          <div style="color:#FF6E4C;" class="mr-4">
            报价率：{{ renderRate(Stats.offeredRate) }}% | 中标率：{{
              renderRate(Stats.selectedRate)
            }}%
          </div>
          <el-popover placement="bottom" width="550" trigger="hover">
            <el-table :data="gridData">
              <el-table-column
                width="90"
                prop="indicators"
                label="指标"
              ></el-table-column>
              <el-table-column
                width="250"
                prop="nounMeaning"
                label="名词释义"
              ></el-table-column>
              <el-table-column prop="formula" label="公式"></el-table-column>
            </el-table>
            <i
              class="el-icon-question text-lg cursor-pointer"
              style="color: #999;"
              slot="reference"
            ></i>
          </el-popover>
        </div>
        <el-table
          :data="tableData"
          border
          stripe
          class="table-base m-t-16"
          size="small"
          style="width: 100%"
          height="400px"
        >
          <el-table-column prop="status" label="状态" width="120">
            <template slot-scope="scope">
              <div
                class="status_box "
                :class="[scope.row.selectedOfferCount ? 'min-h-80' : 'h-full']"
              >
                <div
                  class="unread_offer_count"
                  v-if="scope.row.unreadOfferCount > 0"
                ></div>
                <div
                  class="wait_publish title_label"
                  v-if="scope.row.status == 1"
                >
                  待发布
                </div>
                <div
                  class="wait_offer title_label"
                  v-else-if="scope.row.status == 2"
                >
                  待报价
                </div>
                <div
                  class="has_offer title_label"
                  v-else-if="scope.row.status == 3"
                >
                  已报价({{ scope.row.offeredCount }})
                </div>
                <div
                  class="wait_publish title_label"
                  v-else-if="scope.row.status == 4"
                >
                  已放弃
                </div>
                <div
                  class="equirying m-t-4"
                  v-if="scope.row.subStatus == 'enquirying'"
                >
                  询价中
                </div>
                <div
                  class="equirying_stop  m-t-4"
                  v-else-if="scope.row.subStatus == 'stoped'"
                >
                  停止询价
                </div>
                <div class="winning" v-if="scope.row.selectedOfferCount">
                  {{ scope.row.selectedOfferCount }}家中标
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="enquiryCode" label="单据号" width="188">
          </el-table-column>
          <el-table-column prop="companyName" label="询报价人" width="240">
            <template slot-scope="scope">
              <div class="enquiry_offer">
                <!-- <div class="flex items-center">
                  <span class="en_type mr-8">询</span>
                  <span class="mr-8">{{ scope.row.companyName }}</span>
                  <span class="companyType mr-8">{{
                    scope.row.companyType
                  }}</span>
                </div> -->
                <div class="m-t-4">
                  <span>
                    {{ scope.row.userName }}
                  </span>
                  <span v-if="scope.row.companyName">
                    ({{ scope.row.companyName }})
                  </span>
                  <span>-{{ scope.row.userPhone }}</span>
                  <!-- <i
                    class="iconfont icon-line_message1 cursor-pointer ml-8"
                  ></i> -->
                </div>
                <div class="m-t-4" v-if="scope.row.status == 1">
                  询价时间：{{ renderTime(scope.row.createdAt) }}
                </div>
                <div class="m-t-4" v-else>
                  询价时间：{{ renderTime(scope.row.publishTime) }}
                </div>
                <!-- <div class="flex items-center m-t-4">
                  <span class="offer_type mr-8">报</span>
                  <span>陈好-18866686868</span>
                </div> -->
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="factoryBrand" label="车辆信息" width="132">
            <template slot-scope="scope">
              <div v-if="scope.row.enquiryType == 0">
                {{ scope.row.factoryBrand }} {{ scope.row.series }}
                {{ scope.row.year }} {{ scope.row.displacement }}
                {{ scope.row.gearbox }}
              </div>
              <div v-if="scope.row.enquiryType == 1">
                {{
                  scope.row.brandsScope && scope.row.brandsScope.length
                    ? scope.row.brandsScope.join("、")
                    : ""
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="quality" label="品质要求" width="120">
            <template slot-scope="scope">
              {{
                scope.row.quality && scope.row.quality.length
                  ? scope.row.quality.join("、")
                  : "无限制"
              }}
            </template>
          </el-table-column>
          <el-table-column prop="content" label="询价内容">
            <template slot-scope="scope">
              {{
                scope.row.content && scope.row.content.length
                  ? scope.row.content.join("、")
                  : ""
              }}
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            align="center"
            label="操作"
            width="120"
          >
            <template slot-scope="scope">
              <div v-if="scope.row.status == 1" class="flex flex-col">
                <div>
                  <el-button
                    type="text"
                    size="mini"
                    @click="publishFn(scope.row)"
                  >
                    发布询价
                  </el-button>
                </div>

                <div>
                  <el-button
                    type="text"
                    size="mini"
                    class="text-black-200"
                    @click="deleteFn(scope.row)"
                  >
                    删除询价
                  </el-button>
                </div>
              </div>
              <div v-if="scope.row.status == 2">
                <el-button
                  v-if="scope.row.subStatus == 'stoped'"
                  type="text"
                  size="mini"
                  class="text-black-200"
                  @click="startFn(scope.row)"
                >
                  开启询价
                </el-button>
                <el-button
                  v-else-if="scope.row.subStatus == 'enquirying'"
                  type="text"
                  size="mini"
                  class="text-black-200"
                  @click="stopFn(scope.row)"
                >
                  停止询价
                </el-button>
              </div>
              <div v-if="scope.row.status == 3">
                <div>
                  <el-button
                    type="text"
                    size="mini"
                    @click="lookOfferFn(scope.row)"
                  >
                    查看报价
                  </el-button>
                </div>

                <div>
                  <el-button
                    v-if="scope.row.subStatus == 'stoped'"
                    type="text"
                    size="mini"
                    class="text-black-200"
                    @click="startFn(scope.row)"
                  >
                    开启询价
                  </el-button>
                  <el-button
                    v-else-if="scope.row.subStatus == 'enquirying'"
                    type="text"
                    size="mini"
                    class="text-black-200"
                    @click="stopFn(scope.row)"
                  >
                    停止询价
                  </el-button>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="m-t-16 text-right"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageInfo.page"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="pageInfo.pageSize"
          layout="total,  prev, pager, next, jumper,sizes"
          :total="totalSize"
        >
        </el-pagination>
        <!-- 报价详情 -->
        <EnquiryDetail
          ref="EnquiryDetail"
          :enquiryCode="enquiryCode"
          @refresh="refreshFn"
        />
      </div>
    </div>
    <iconChat />
  </div>
</template>

<script>
import SearchTemplate from "@/components/search/SearchTemplate.vue";
import EnquiryDetail from "./components/enquiryDetail.vue";
import iconChat from "@/components/iconChat/iconChat";

import { getDict } from "@/api/basic";
import {
  enquiryList, //我的询价列表
  enquiryPublishById, //发布询价单
  enquiryStop,
  enquiryRestart,
  enquiryDelete,
} from "@/api/enquiry";

const defaultFormData = {
  // page: 0, //# 页码
  // pageSize: 0, //# 页宽
  status: [],
  quality: [],
  startTime: "", // # 加入时间起始
  endTime: "", //# 加入时间截止
  keyword: "", //# 关键字
  // pushArea: "none", //# 推送地区，none:无限制；thisCity:本市；thisProvince:本省
  invoiceType: [], //# 发票类型
  onlyEnquiry: false, //仅看询价中的
  onlySelected: false, //仅看已中标的
  onlyOfferUnRead: false, //只看未读报价询价单
};

export default {
  name: "myEnquiry",
  components: {
    SearchTemplate,
    EnquiryDetail,
    iconChat,
  },
  props: {
    source: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      placeholder: "询价单号/车型品牌",
      formData: JSON.parse(JSON.stringify(defaultFormData)),
      joinTime: [],
      statusOptions: [
        //# 状态:默认空值:全部,1:待发布;2:待报价;3:已报价
        // { name: "全部", code: null },
        { name: "待发布", code: 1 },
        { name: "待报价", code: 2 },
        { name: "已报价", code: 3 },
        // { name: "已放弃", code: 4 },
      ],
      qualityOptions: [], //品质
      invoiceTypeOptions: [], //发票类型
      areaOptions: [
        //收货地区
        {
          code: "none",
          name: "无限制",
        },
        {
          code: "thisCity",
          name: "本市",
        },
        {
          code: "thisProvince",
          name: "本省",
        },
      ],
      pageInfo: {
        page: 1,
        pageSize: 20,
      },
      totalSize: 0,
      tableData: [
        // {
        //   enquiryCode: "XJ202101081410222803", // # 询价单号
        //   enquiryType: 0, //# 询价类型，0:车型询价，1:范围询价，2:通信快速询价
        //   userId: 0, // # 发布者用户ID（users.id）
        //   companyId: 0, // # 发布者公司ID (companies.id）
        //   status: 1, //# 状态,1:待发布;2:待报价;3:已报价;
        //   subStatus: "", // # 子状态,enquirying:询价中;stoped:停止询价;
        //   vehBrand: "", //# 汽车品牌
        //   factoryBrand: "Mini", // # 汽车厂牌
        //   series: "Cabrio", //# 车系
        //   year: "2020", //# 年款
        //   displacement: "1.5T", // # 排量
        //   gearbox: "7DCT", //# 变速器
        //   vin: "", //# vin码
        //   brandsScope: [
        //     //# 品牌范围
        //     "",
        //   ],
        //   pictures: [
        //     //# 上传的图片
        //     "",
        //   ],
        //   remarks: "", //# 说明
        //   invoiceType: "", // # 发票类型,存中文
        //   quality: [
        //     //# 品质说明,存中文
        //   ],
        //   pushArea: "", //# 推送地区，none:无限制；thisCity:本市；thisProvince:本省
        //   receiveAreaId: 0, //# 收货地址所在区域县级ID
        //   receiveName: "", //# 收货地址中联系人
        //   receivePhone: "", //# 收货地址中联系电话
        //   receiveAddress: "", //# 收货地址中详细地址
        //   receiverCount: 0, //# 接收者数量
        //   publishTime: "2021-09-09 14:10:22", //# 询价发布时间
        //   offeredCount: 0, //# 已报价人数
        //   unreadOfferCount: 0, // # 未读报价单数
        //   selectedOfferCount: 0, // # 中标商家数
        //   createdBy: 0, //# 创建者
        //   createdAt: "2022-03-24 17:44:27", //# 创建时间
        //   updatedBy: 0,
        //   updatedAt: "0001-01-01T00:00:00Z",
        //   companyName: "李钟硕的分公司", //# 询价公司名称
        //   companyType: "汽修厂", //# 询价公司类型
        //   userName: "询价人", //# 询价人名称
        //   userPhone: "13200000000", //# 询价人电话
        //   content: [
        //     //# 询价内容
        //     "中网亮条（上右）",
        //     "中网亮条（下右）",
        //     "中网上装饰板",
        //     "轮胎",
        //     "火花塞",
        //   ],
        // },
      ],
      Stats: {
        offeredRate: "0", // # 报价率
        selectedRate: "0", //# 中标率
        completeRate: "0", //# 报价完整率
        avgDuration: 0, //# 平均报价时长
      },
      gridData: [
        {
          indicators: "报价率",
          nounMeaning: "已报价的询价单占发布的询询价的比例",
          formula: "报价率=已报价单数/询价单数*100%",
        },
        {
          indicators: "中标率",
          nounMeaning: "已中标的询价单占已报价的询价单的比例",
          formula: "中标率=已中标单数/已报价的询价单数*100%",
        },
      ],
      enquiryCode: "",
      timer: null,
      operate: null, //是否是通过im消息跳转
    };
  },
  created() {
    this.getQualityList();
    this.getInvoiceTypeList();
  },
  mounted() {
    if (this.$route.query.keywords && this.$route.query.operate) {
      this.formData.keyword = this.$route.query.keywords;
      this.operate = this.$route.query.operate;
      this.initData();
    } else {
      this.initData();
      //设置定时器 5s 请求一次
      // this.timer = setInterval(() => {
      //   this.initData();
      // }, 5000);
    }
    setTimeout(() => {
      if (!this.$store.state.profile.id) {
        this.$router.push("/login");
      }
    }, 2000);
  },
  // destroyed() {
  //   clearInterval(this.timer);
  // },
  methods: {
    renderRate(rate) {
      let n = 0;
      if (rate == "1") {
        n = 100;
      } else if (rate == "0") {
        n = 0;
      } else {
        let r = rate.split(".")[1];
        if (r.length == 2) {
          n = Number(r);
        } else if (r.length == 1) {
          n = Number(r + "0");
        }
      }

      return n;
    },
    refreshFn() {
      this.operate = null;
      this.initData();
    },
    renderTime(time) {
      return this.$dayjs(time).format("YYYY-MM-DD HH:mm:ss");
    },
    publishFn(row) {
      enquiryPublishById({ id: row.id }).then(() => {
        this.$message.success("发布询价成功！");
        this.pageInfo.page = 1;
        this.initData();
      });
    },
    deleteFn(row) {
      this.$confirm(`确定要删除询价单[${row.enquiryCode}]吗?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        enquiryDelete({
          enquiryCode: row.enquiryCode,
        }).then(() => {
          this.$message.success("删除成功！");
          this.pageInfo.page = 1;
          this.initData();
        });
      });
    },
    stopFn(row) {
      this.$confirm(`询价单[${row.enquiryCode}]确定要停止询价吗?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        enquiryStop({
          enquiryId: row.id,
        }).then(() => {
          this.$message.success("已停止询价！");
          this.initData();
        });
      });
    },
    startFn(row) {
      enquiryRestart({
        enquiryId: row.id,
      }).then(() => {
        this.$message.success("询价已开启！");
        this.initData();
      });
    },
    lookOfferFn(row) {
      this.enquiryCode = row.enquiryCode;
      this.$refs.EnquiryDetail.show();
    },
    toPostEnquiryFn() {
      let obj = {
        path: "/home/postEnquiry",
      };
      this.$router.push(obj);
    },
    getQualityList() {
      getDict({ type: "quality" }).then((res) => {
        this.qualityOptions = res || [];
      });
    },
    getInvoiceTypeList() {
      getDict({ type: "invoiceType" }).then((res) => {
        this.invoiceTypeOptions = res || [];
      });
    },
    initData() {
      let params = {
        ...this.formData,
        keywords: this.formData.keyword,
        ...this.pageInfo,
      };
      delete params.keyword;
      enquiryList(params).then((res) => {
        this.tableData = res?.Rows || [];
        this.Stats = res?.Stats || [];
        this.totalSize = res?.totalSize;
        if (this.operate) {
          let curRow = this.tableData[0];
          if (curRow.status == 3) {
            //im 跳转查看报价
            this.lookOfferFn(curRow);
          }
        }
      });
    },
    handleQuery() {
      this.pageInfo.page = 1;
      this.initData();
    },
    handleReset() {
      this.formData = JSON.parse(JSON.stringify(defaultFormData));
      this.joinTime = [];
    },
    dateChangeFn(date) {
      if (date) {
        this.formData.startTime = date[0];
        this.formData.endTime = date[1];
      } else {
        this.formData.startTime = "";
        this.formData.endTime = "";
      }
    },
    handleSizeChange(val) {
      this.pageInfo.pageSize = val;
      this.initData();
    },
    handleCurrentChange(val) {
      this.pageInfo.page = val;
      this.initData();
    },
    renderUserType(item) {
      let str = "";
      if (item.cooperatorType) {
        if (item.companyType == "dealer") {
          str = "公司-经销商";
        } else if (item.companyType == "garage") {
          str = "公司-修理厂";
        } else if (item.companyType == "manufacturer") {
          str = "公司-生产厂家";
        }
      } else {
        str = "个人";
      }
      return str;
    },
    isSourceOfferManage() {
      return this.source === "offerManage";
    }
  },
};
</script>

<style lang="less" scoped>
.enquiry {
  // /deep/.el-drawer__wrapper {
  //   z-index: 1000000 !important;
  // }
  /deep/.table-base .el-table__row td:first-child .cell {
    height: 100%;
  }
  .status_box {
    position: relative;
  }
  .min-h-80 {
    min-height: 80px;
  }
  .unread_offer_count {
    position: absolute;
    top: -4px;
    right: -6px;
    width: 8px;
    height: 8px;
    background: #ff4d4f;
    border-radius: 50%;
  }
  .title_label {
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: left;
    color: #ffffff;
    line-height: 20px;
    padding: 2px 8px;
    margin-left: -10px;
  }
  .wait_publish {
    width: fit-content;
    background: #bfbfbf;
    border-radius: 0px 29px 29px 0px;
  }
  .wait_offer {
    width: fit-content;
    background: #ff6e4c;
    border-radius: 0px 29px 29px 0px;
  }
  .has_offer {
    width: fit-content;
    background: #3bba98;
    border-radius: 0px 29px 29px 0px;
  }
  .equirying::before {
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    line-height: 8px;
    background: #3bba98;
    border-radius: 50%;
    margin-right: 8px;
  }
  .equirying_stop::before {
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    line-height: 8px;
    background: #ff4d4f;
    border-radius: 50%;
    margin-right: 8px;
  }
  .winning {
    position: absolute;
    left: -27px;
    bottom: 0;
    width: 70px;
    padding: 2px;
    font-size: 11px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: center;
    color: #ffffff;
    line-height: 16px;
    background: #3bba98;
    transform: rotate(45deg);
  }
  .enquiry_offer {
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: left;
    color: #333333;
    line-height: 22px;
    .en_type {
      font-size: 12px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      color: #ffffff;
      line-height: 20px;
      background: #ff6e4c;
      border-radius: 2px;
      color: #ffffff;
      padding: 2px 8px;
    }
    .companyType {
      font-size: 12px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      color: #ff6e4c;
      line-height: 20px;
      background: #faeee1;
      border: 1px solid #ff6e4c;
      border-radius: 2px;
      padding: 2px 8px;
    }
    .offer_type {
      font-size: 12px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      color: #ffffff;
      line-height: 20px;
      background: #3bba98;
      border-radius: 2px;
      color: #ffffff;
      padding: 2px 8px;
    }
  }
}
</style>
