<template>
  <div class="enquiryStoreSelect h-full flex flex-col">
    <div class="flex-1 space-y-8">
      <template v-for="(item, index) in formData.details">
        <div class="parts" :key="index">
          <div class="flex_div align_center m-b-4">
            <!-- 未中标 -->
            <div v-if="showCheck" @click.prevent="checkShop(item)">
              <el-checkbox v-model="item.checked" :disabled="!item.items">
              </el-checkbox>
            </div>

            <div class="parts_header flex_1 m-l-8">
              <div class="name blod_text">
                {{ item.partName }}{{ item.partCode }}
              </div>
              <div class="num size14_text">X {{ item.qty }}</div>
            </div>
          </div>

          <div class="parts_list" v-if="item.items && item.items.length">
            <div
              class="flex_div m-t-8"
              v-for="(part, p_i) in item.items"
              :key="p_i + 'p'"
            >
              <!-- 未中标 -->
              <div v-if="showCheck" @click.prevent="isCheck(item, part)">
                <el-checkbox v-model="part.isChecked"> </el-checkbox>
              </div>

              <div class="item flex_1 m-l-8">
                <div class="item_content">
                  <div class="size14_text">
                    <span class="text-warning m-r-4">{{ part.quality }}</span>
                    <span>
                      {{ part.partName }} {{ part.partCode }}
                      {{ part.productionPlace }} {{ part.brand }}
                    </span>
                  </div>
                  <div class="gray_text m-t-4">
                    {{ part.remarks }}
                  </div>
                  <div class="item_flex flex_between m-t-6">
                    <div class="item_flex">
                      <div
                        class="theme_text flag m-r-4"
                        v-if="part.guaranteePeriod > 0"
                      >
                        {{ part.guaranteePeriod }}天质保
                      </div>
                      <div
                        class="theme_text flag"
                        v-if="part.deliveryPeriod > 0"
                      >
                        供货期{{ part.deliveryPeriod }}天
                      </div>
                    </div>
                    <div class="price text-sm font-semibold text-warning">
                      ￥{{ part.price }}/件
                    </div>
                  </div>
                </div>
                <div class="winning" v-if="part.flags > 0">已中标</div>
              </div>
            </div>
          </div>
          <div
            class="item size14_text text-warning"
            :class="[showCheck ? 'm-l-24' : 'm-l-8']"
            v-else
          >
            已放弃报价
          </div>
        </div>
      </template>
    </div>
    <div class="flex items-center justify-between mt-8" v-if="showCheck">
      <div class="flex">
        <div @click.prevent="checkAll">
          <el-checkbox v-model="isAllChecked">全选</el-checkbox>
        </div>
        <div class="blod_text text-warning m-l-12">
          预估: ￥{{ allPrice }}元
        </div>
      </div>
      <el-button
        class="confirm mr-56"
        type="primary"
        size="small"
        :disabled="allPrice <= 0"
        @click="iWantFn"
        >我要了，送过来</el-button
      >
    </div>
  </div>
</template>

<script>
import { enquiryOfferDetail, enquirySelected } from "@/api/enquiry";

export default {
  name: "enquiryStoreSelect",
  props: {
    subStatus: {
      type: String,
    },
    curOffer: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      formData: {
        id: 0, //自增ID
        enquiryCode: "", //询价单号
        status: "", //状态,waitOffer:待报价;offered:已报价;givenUp:已放弃
        companyId: 0, //接收者 (companies.id）
        deliverAreaId: 0, //发货地区，0表示未设置
        pictures: [
          //图片地址数组
          "",
        ],
        remarks: "", //备注
        enquiryDetailCount: 0, //询价明细数
        offeredEnquiryDetailCount: 0, //已报询价明细数
        selectedEnquiryDetailCount: 0, //已中标询价明细数
        offeredDuration: 0, //报价时长,发布询价到报价的时间(s)
        ownerUserId: 0, //所属人
        offerTime: "0001-01-01T00:00:00Z", //报价时间
        overdueType: "", //报价过期类型，none:始终有效；oneHour:1小时；oneDay:24小时；threeDay:3天；thirtyDay:30天
        flags: 0, //标记位,第一位:询价方未读;
        createdBy: 0, //创建者
        createdAt: "0001-01-01T00:00:00Z", //创建时间
        updatedBy: 0, //更新者
        updatedAt: "0001-01-01T00:00:00Z", //更新时间
        companyName: "", //报价公司名称
        details: [
          // {
          //   id: 0, //自增ID
          //   enquiryCode: "", //单据号
          //   partId: 0, //配件ID/软件内配件Id
          //   partName: "配件名称", //配件名称,询价关键字
          //   partCode: "0001", //配件编码
          //   partType: "", //配件类型，hand:手动填写；choose:从基础分类中选取；searchChoose:配件查询中选择
          //   poperty: "", //配件性质
          //   brand: "", //配件品牌
          //   productionPlace: "", //产地
          //   quality: "", //品质说明,erp保险认证分类,中文
          //   qty: "0", //数量,默认为1
          //   stdPartName: "", //标准配件名称
          //   stdPartCode: "", //标准配件oe号
          //   price4s: "0", //4S价
          //   createdBy: 0, //创建者
          //   createdAt: "0001-01-01T00:00:00Z", //创建时间
          //   updatedBy: 0, //更新者
          //   "*updatedAt": "0001-01-01T00:00:00Z", //更新时间
          //   items: [
          //     //报价信息
          //     {
          //       id: 0, //自增ID
          //       offerId: 0, //接收的询价主键ID
          //       enquiryDetailId: 0, //询价单详情主键ID
          //       partId: 0, //配件ID/软件内配件Id
          //       property: "", //配件性质
          //       quality: "", //品质说明，存中文
          //       "*price": "0", //价格
          //       partName: "", //配件名称
          //       partCode: "", //配件编码
          //       productionPlace: "", //配件产地
          //       brand: "", //配件品牌
          //       stockQty: "0", //报价当时的库存数量，仅展示用，也可不存
          //       guaranteePeriod: 0, //质保期
          //       deliveryPeriod: 0, //供货周期
          //       remarks: "", //备注
          //       flags: 0, //标记位,第一位:已中标;
          //       createdBy: 0, //创建者
          //       createdAt: "0001-01-01T00:00:00Z", //创建时间
          //       updatedBy: 0, //更新者
          //       "*updatedAt": "0001-01-01T00:00:00Z" //更新时间
          //     }
          //   ]
          // },
        ],
      },
      allPrice: "0",
      allShops: 0,
      allCount: 0,
      checkedList: [],
      isAllChecked: false, //是否全选
    };
  },
  computed: {
    disabledCount() {
      let count = 0;
      this.formData.details.forEach((p) => {
        if (!p.items) {
          count += 1;
        }
      });
      return count;
    },
    showCheck() {
      return (
        this.formData.selectedEnquiryDetailCount == 0 &&
        this.formData.enquiry?.subStatus != "stoped" &&
        !this.curOffer.expired
      );
    },
  },
  watch: {
    curOffer: {
      handler(val) {
        if (val && val.id) {
          this.getInit(val.id);
        }
      },
      deep: true,
    },
    "formData.details": {
      handler(val) {
        this.allPrice = 0;
        val.forEach((item) => {
          let partList = item.items;
          if (partList && partList.length) {
            partList.forEach((p) => {
              if (p.isChecked) {
                this.allPrice = (+this.allPrice + p.price * item.qty).toFixed(
                  2
                );
              }
            });
          }
        });
      },
      deep: true,
    },
  },
  mounted() {
    // this.getInit();
  },
  methods: {
    getInit(offerId) {
      if (!offerId) return;
      enquiryOfferDetail({
        offerId: Number(offerId),
      }).then((res) => {
        this.formData = res || {};
        if (this.showCheck) {
          let arr = [];
          res.details.forEach((p) => {
            if (p.items && p.items.length) {
              p.items.forEach((q) => {
                q.isChecked = false;
              });
            }
            arr.push({
              ...p,
              checkedCount: 0,
              checked: false,
            });
          });
          this.formData.details = [...arr];
        }
      });
    },
    iWantFn() {
      let offerDetailIds = [];
      this.formData.details.forEach((p) => {
        if (p.items && p.items.length) {
          p.items.forEach((q) => {
            if (q.isChecked) {
              offerDetailIds.push(q.id);
            }
          });
        }
      });
      // console.log("offerDetailIds", offerDetailIds);

      let params = {
        offerId: this.formData.id, //报价ID
        offerDetailIds, //报价明细ID
      };
      enquirySelected(params).then(() => {
        this.$message.success("选择成功！");
        this.$emit("refresh");
      });
    },
    //全选、全不选
    checkAll() {
      this.isAllChecked = !this.isAllChecked;
      if (this.isAllChecked) {
        this.formData.details.forEach((item) => {
          if (item.items && item.items.length) {
            this._shopTrue(item);
          }
        });
      } else {
        this.formData.details.forEach((item) => {
          this._shopFalse(item);
        });
      }
    },
    //点击单个配件
    isCheck(item, part) {
      // console.log("item", item);
      // console.log("part", part);
      //为未选中的的时候改变为false,反之为true
      !part.isChecked
        ? this._checkTrue(item, part)
        : this._checkFalse(item, part);
    },
    _checkTrue(item, part) {
      part.isChecked = true;
      if (++item.checkedCount === item.items.length) {
        item.checked = true;
      } else {
        return "";
      }
      if (item.checked) {
        if (
          ++this.allShops ===
          this.formData.details.length - this.disabledCount
        ) {
          this.isAllChecked = true;
        } else {
          this.isAllChecked = false;
        }
      }
    },
    _checkFalse(item, part) {
      part.isChecked = false;
      --item.checkedCount;
      if (item.checked) {
        item.checked = false;
        --this.allShops;
      }
      this.isAllChecked = false;
    },
    checkShop(item) {
      !item.checked ? this._shopTrue(item) : this._shopFalse(item);
    },
    _shopTrue(item) {
      if (!item.items) return;
      item.items.forEach((p) => {
        if (p.isChecked === false) {
          this._checkTrue(item, p);
        } else {
          return "";
        }
      });
    },
    _shopFalse(item) {
      if (!item.items) return;
      item.items.forEach((p) => {
        if (p.isChecked === true) {
          this._checkFalse(item, p);
        } else {
          return "";
        }
      });
    },
    // _totalPrice() {
    //   this.allPrice = 0;
    //   this.formData.details.forEach((item) => {
    //     let partList = item.items;
    //     partList.forEach((p) => {
    //       if (p.isChecked) {
    //         this.allPrice = (+this.allPrice + p.price * p.qty).toFixed(2);
    //       }
    //     });
    //   });
    // },
  },
};
</script>

<style lang="less" scoped>
.enquiryStoreSelect {
  padding: 12px;
  font-size: 12px;
  .theme_text {
    font-size: 12px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: left;
    color: #ff6e4c;
    line-height: 16px;
  }
  .blod_text {
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    text-align: left;
    // color: #333333;
    line-height: 20px;
  }
  .size14_text {
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: left;
    // color: #333333;
    line-height: 20px;
  }
  .parts {
    background: #ffffff;
    border-radius: 8px;
    .parts_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .flex_div {
      display: flex;
    }
    .align_center {
      align-items: center;
    }
    .flex_1 {
      flex: 1;
    }
    .item {
      background: #f4f4f4;
      border-radius: 8px;
      padding: 12px;
      position: relative;
      overflow: hidden;
      .item_content {
      }
      .item_flex {
        display: flex;
        align-items: center;
      }
      .flex_between {
        justify-content: space-between;
      }
      .flag {
        padding: 0 4px;
        background: #ffffff;
        border: 0.5px solid #ff6e4c;
        border-radius: 2px;
      }
      .winning {
        position: absolute;
        right: -18px;
        top: 9px;
        width: 70px;
        padding: 2px;
        font-size: 11px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: center;
        color: #ffffff;
        line-height: 16px;
        background: #3bba98;
        transform: rotate(45deg);
      }
    }
  }
  .offer {
    flex: 1;
    overflow: auto;
    .offer_content {
      background: #ffffff;
      border-radius: 8px;
      padding: 12px;
      margin-bottom: 8px;
      font-size: 12px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: left;
      color: #666666;
      line-height: 16px;
      position: relative;
      overflow: hidden;
      .offer_title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title_l {
          display: flex;
          align-items: center;
        }
        img {
          width: 20px;
          height: 20px;
        }
      }
      .winning {
        position: absolute;
        right: -18px;
        bottom: 8px;
        width: 70px;
        padding: 2px;
        font-size: 11px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: center;
        color: #ffffff;
        line-height: 16px;
        background: #3bba98;
        transform: rotate(315deg);
      }
    }
  }
  .confirm{
    margin-right: 56px;
  }
}
</style>
